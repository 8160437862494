// ** MUI Imports
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

const FallbackSpinner = ({ sx }) => (
  <Box
    sx={{
      height: "100vh",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      ...sx
    }}
  >
    <div style={{ padding: "24px 44px", display: "flex" }}>
      <img alt="" style={{ width: "75px" }} src="/images/Logo.svg" />
      <Typography sx={{ ml: 3 }} variant="h2">
        KAMlink
      </Typography>
    </div>
  </Box>
);

export default FallbackSpinner;
