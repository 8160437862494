import { createSlice } from "@reduxjs/toolkit";

export const layoutSlice = createSlice({
  name: "misc",
  initialState: {
    displayNotFound: false,
    showAppErrorPage: false
  },
  reducers: {
    handleDisplayNotFound: (state, action) => {
      state.displayNotFound = action.payload;
    },
    handleErrorPage: (state, action) => {
      state.showAppErrorPage = action.payload;
    }
  }
});

export const { handleDisplayNotFound, handleErrorPage } = layoutSlice.actions;

export default layoutSlice.reducer;
