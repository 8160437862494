import toast from "react-hot-toast";
export const isUserLoggedIn = () => localStorage.getItem("userData");

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

export const generateUrl = (path, searchParams = {}, basePath) => {
  if (undefined === basePath) {
    basePath = `${window.location.protocol}//${window.location.host}${process.env.REACT_APP_BASENAME}`;
  }
  const url = new URL(`${basePath}${path}`);
  for (const [k, v] of Object.entries(searchParams)) {
    url.searchParams.append(k, v);
  }
  return url.toString();
};

export const generateAdminUrl = (path, searchParams = {}) => {
  const basePath =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_ADMIN_URL
      : window.location.origin;
  return generateUrl(path, searchParams, basePath);
};

export const generateSubdomainUrl = (subDomain, path) =>
  `${process.env.REACT_APP_URL.replace("app", subDomain)}${path}`;

export const getSubDomain = () => window.location.hostname.split(".")[0];

export const generateIRI = (resource, uuid) => `/api/${resource}/${uuid}`;

export const objectLength = (obj) => (obj ? Object.keys(obj).length : 0);

export const formatBytes = (bytes) =>
  bytes >= 1024 * 1024
    ? `${(bytes / (1024 * 1024)).toFixed(1)} MB`
    : `${(bytes / 1024).toFixed(1)} KB`;

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const AL = (data) => alert(JSON.stringify(data));
export const parseJSON = (data) => data !== "undefined" && JSON.parse(data);
export const getLocal = (key) => parseJSON(localStorage.getItem(key));
export const isAuthenticated = () => localStorage.getItem("token");
export const setLocal = (key, value) => localStorage.setItem(key, JSON.stringify(value));
export const rmLocal = (key) => localStorage.removeItem(key);

export const setLocalStorageWithExpiry = (key, value, expirationMinutes) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + expirationMinutes * 60 * 1000 // Convert minutes to milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const logoutUser = () => {
  rmLocal("token");
  rmLocal("user_details");
};

export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};
// Function to detect changes between two objects based on the values
export function isUpdated(originalValues, currentValues) {
  for (const key in originalValues) {
    if (originalValues.hasOwnProperty(key) && currentValues.hasOwnProperty(key)) {
      if (originalValues[key] !== currentValues[key]) {
        return true; // Changes detected
      }
    }
  }
  return false;
}

export const isObject = (obj) => obj && typeof obj === "object" && Object.keys(obj)?.length > 0;

export function extractUUIDFromUrl(url) {
  if (!url) {
    return null;
  }
  const segments = url?.split("/");
  return segments?.[segments.length - 1] || null;
}

export const objectToFormData = (obj) => {
  const formData = new FormData();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key]);
    }
  }

  return formData;
};

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const objectFilled = (state) => {
  for (const key in state) {
    if (!state[key]) {
      return false;
    }
  }
  return true;
};

export const nullOrUndefine = (val) => val === null || val === undefined;

export const throwError = (message) => toast.error(message);

export const throwSuccess = (message) => toast.success(message);
