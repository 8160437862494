import { createSlice } from "@reduxjs/toolkit";
import { rmLocal, setLocal } from "src/utility/Utils";

export const layoutSlice = createSlice({
  name: "auth",
  initialState: {
    userData: {},
    token: null,
    completedStep: 0,
    isProfileIncomplete: false,
    organization: null,
    facilities: []
  },
  reducers: {
    resetAuth: (state) => {
      state.userData = {};
      state.token = null;
      state.completedStep = 0;
      state.isProfileIncomplete = false;
      state.organization = null;
      state.facilities = [];
      rmLocal("token");
    },
    updateCurrentUser: (state, { payload }) => {
      state.userData = { ...state.userData, ...payload };
    },
    updateToken: (state, { payload }) => {
      state.token = payload;
      if (payload) {
        setLocal("token", payload);
      }
    },
    isProfileIncomplete: (state, { payload }) => {
      state.isProfileIncomplete = payload;
    },
    updateProfileStep: (state, { payload }) => {
      state.completedStep = parseInt(payload);
    },
    updateOrganization: (state, { payload }) => {
      state.organization = payload;
    },
    updateFacilities: (state, { payload }) => {
      state.facilities = [...state.facilities, payload];
    },
    updateAuth: (state, { payload }) => {
      state = payload;
    }
  }
});

export const {
  resetAuth,
  updateCurrentUser,
  updateToken,
  isProfileIncomplete,
  updateProfileStep,
  updateOrganization,
  updateFacilities,
  updateAuth
} = layoutSlice.actions;

export default layoutSlice.reducer;
