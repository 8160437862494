// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** ThemeConfig Import
import themeConfig from "src/configs/themeConfig";

export const layoutSlice = createSlice({
  name: "settings",
  initialState: {
    themeColor: "primary",
    mode: themeConfig.mode,
    skin: themeConfig.skin,
    footer: themeConfig.footer,
    layout: themeConfig.layout,
    lastLayout: themeConfig.layout,
    direction: themeConfig.direction,
    navHidden: themeConfig.navHidden,
    appBarBlur: themeConfig.appBarBlur,
    navCollapsed: themeConfig.navCollapsed,
    contentWidth: themeConfig.contentWidth,
    toastPosition: themeConfig.toastPosition,
    verticalNavToggleType: themeConfig.verticalNavToggleType,
    appBar:
      themeConfig.layout === "horizontal" && themeConfig.appBar === "hidden"
        ? "fixed"
        : themeConfig.appBar
  },
  reducers: {
    saveDirection: (state, action) => {
      state.direction = action.payload;
    },
    saveMode: (state, action) => {
      state.mode = action.payload;
    },
    saveNavCollapsed: (state, action) => {
      state.navCollapsed = action.payload;
    },
    saveLayout: (state, action) => {
      state.layout = action.payload;
    }
  }
});

export const { saveDirection, saveMode, saveNavCollapsed, saveLayout } = layoutSlice.actions;

export default layoutSlice.reducer;
