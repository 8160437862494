import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Spinner from "src/@core/components/spinner";

const root = ReactDOM.createRoot(document.getElementById("root"));
const LazyApp = lazy(() => import("./App"));

root.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <LazyApp />
    </Suspense>
  </Provider>
);
reportWebVitals();
